<template>
  <div class="md-content md-table-content">
    <md-table
      class="globe-activity Screeningonhold responsive"
      :md-sort.sync="listInput.sortText"
      :md-sort-order.sync="listInput.sortOrder"
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
    >
      <!-- md-fixed-header -->

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <!-- <md-table-cell v-if="item.holderId == userId" md-label="Action"> -->
        <md-table-cell
          v-if="userClaims.includes(Permissions.ReaderRequestRelease)"
          md-label="Action"
        >
          <md-button
            v-if="item.holderId == userId"
            class="md-green-outline"
            style="width: 90%"
            @click.native="ReleaseClick(item)"
            >Release</md-button
          >
          <md-button
            v-else
            class="md-danger-outline"
            style="width: 90%"
            @click.native="RequestClick(item)"
            >Request Release</md-button
          >
        </md-table-cell>
        <md-table-cell v-else md-label="Action">
          <md-button
            class="md-green-outline"
            style="width: 90%"
            @click.native="ReleaseClick(item)"
            >Release</md-button
          >
        </md-table-cell>
        <md-table-cell
          md-label="Name"
          md-sort-by="name"
          @click.native="gotoEval(item)"
          >{{ item.name }}</md-table-cell
        >
        <md-table-cell
          md-label="Email"
          md-sort-by="email"
          @click.native="gotoEval(item)"
          >{{ item.email }}</md-table-cell
        >
        <md-table-cell md-label="Technician" @click.native="gotoEval(item)">{{
          item.technician
        }}</md-table-cell>
        <md-table-cell md-label="Holder" @click.native="gotoEval(item)">{{
          item.holder
        }}</md-table-cell>
        <md-table-cell
          md-label="Device"
          md-sort-by="device"
          @click.native="gotoEval(item)"
          >{{ item.globe }}</md-table-cell
        >
        <!-- <md-table-cell md-label="Status" @click.native="gotoEval(item)">{{
          item.status
        }}</md-table-cell> -->
        <md-table-cell
          md-label="Date"
          md-sort-by="date"
          @click.native="gotoEval(item)"
          >{{ item.screenDate }}</md-table-cell
        >
        <md-table-cell md-label="Start" @click.native="gotoEval(item)">{{
          item.startTime
        }}</md-table-cell>
      </md-table-row>
    </md-table>
    <!-- <ListFooterLayout /> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Success } from "../../../models/SwalMessages";
import { getSubmit } from "../../../services/control-services/SnackbarService";
import { Warning } from "../../../models/SwalMessages";
import { mapState } from "vuex";
export default {
  inject: [
    "listInput",
    "list",
    "refreshList",
    "app",
    "confirmation",
    "showSuccess",
    "showWarning",
    "snackbar",
    "ReleaseStatusUpdate",
    "Permissions",
  ],
  name: "GlobeActivitySlimTable",
  components: {},
  data() {
    console.log("application is :", this.app);
    return {
      userId: this.app.userProfile.id,
    };
  },
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
  },
  methods: {
    ...mapActions("screenings", ["updateHold", "requestHold"]),
    gotoEval(item) {
      this.$store.state.screeningeval.ReferralDoctors = [];
      this.$router.push(this.$route.path + "/Evaluation/" + item.screeningID);
    },
    RequestClick(selectedItem) {
      let warning = new Warning();
      warning.title = "Request Screening?";
      warning.text = "Are you sure you want to request this Screening?";
      warning.confirmButtonClass = "md-button md-info";
      warning.hasGoback = true;
      this.showWarning(warning);
      this.submitSubscription = getSubmit().subscribe(() => {
        this.snackbar.showWarning = false;
        let postformData = {};
        postformData.screeningId = selectedItem.screeningID;
        this.requestHold(postformData)
          .then(() => {
            var success = new Success();
            success.text = "Screening requested successfully";
            this.refreshList();
            this.showSuccess(success);
          })
          .catch((error) => this.showError(error))
          .finally(() => (this.updated = true));
        this.submitSubscription.unsubscribe();
      });
    },

    ReleaseClick(selectedItem) {
      this.ReleaseStatusUpdate(selectedItem, this.refreshList);
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
  },
};
</script>
<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  //padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
  cursor: pointer;
}
.md-table-row:hover {
  background: #d4d5e6;
}

.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.delete-icon {
  cursor: pointer;
}
.Screeningonhold.responsive td:nth-of-type(1):before {
  content: "Action";
}
.Screeningonhold.responsive td:nth-of-type(2):before {
  content: "Name";
}
.Screeningonhold.responsive td:nth-of-type(3):before {
  content: "Email";
}
.Screeningonhold.responsive td:nth-of-type(4):before {
  content: "Technician";
}
.Screeningonhold.responsive td:nth-of-type(5):before {
  content: "Device";
}
.Screeningonhold.responsive td:nth-of-type(6):before {
  content: "Status";
}
.Screeningonhold.responsive td:nth-of-type(7):before {
  content: "Date";
}
.Screeningonhold.responsive td:nth-of-type(8):before {
  content: "Start";
}
</style>
