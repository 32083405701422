<template>
  <div align="Left">
    <list-layout>
      <template v-slot:content>
        <ScreeningsOnholdTable />
      </template>
    </list-layout>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ListLayout from "../../components/layouts/list-layout/ListLayout.vue";
import ScreeningsOnholdTable from "@/components/Globechek/Tables/ScreeningsOnholdTable.vue";
import {
  getSubmit,
  getCancel,
} from "../../services/control-services/ConfirmationService";

export default {
  components: { ScreeningsOnholdTable, ListLayout },
  inject: ["confirmation", "listInput", "list"],
  data() {
    return {};
  },

  created() {},

  methods: {
    ...mapActions("screenings", [
      "getScreeningsAvailable",
      "deleteScreeningsAvailable",
    ]),

    DeleteClick(selectedItem) {
      this.confirmation.title = "Delete Screening ?";
      this.confirmation.description =
        "Are you sure you want to delete this Screening ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.deleteScreeningsAvailable(selectedItem.kiosk_id);
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: 30vh;
  overflow: auto;
}
.md-table-cell {
  padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
</style>
